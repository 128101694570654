import { ReactElement } from 'react';
import { Row, Col } from 'react-bootstrap';
import SearchInput from '../../../../components/search-input/search-input';
import './main-layout-header.scss';
import { SetFormValueByInput } from '../../../../../../core/hook/use-form-inputs';
import MainLayoutHeaderButtons, {
  IMainLayoutHeaderButtonsProps,
} from '../main-layout-header-buttons/main-layout-header-buttons';

export interface IMainLayoutHeaderProps extends IMainLayoutHeaderButtonsProps {
  onChangeSearchValue?: SetFormValueByInput;
}

const MainLayoutHeader = ({
  onChangeSearchValue,
  ...props
}: IMainLayoutHeaderProps): ReactElement => {
  return (
    <Row>
      <Col xl={6} className="mb-5">
        <SearchInput onChange={onChangeSearchValue} />
      </Col>
      <Col xl={6} className="d-xl-flex justify-content-end d-inline">
        <MainLayoutHeaderButtons {...props} />
      </Col>
    </Row>
  );
};

export default MainLayoutHeader;
