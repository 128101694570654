import { ReactElement, useContext } from 'react';
import CustomDropdown from '../../../../core/components/custom-dropdown/custom-dropdown';
import { Row, Col } from 'react-bootstrap';
import { IDestinationVisualizationProps } from './destination-visualization/destination-visualization';
import LeavingPointFormInputs from './leaving-point-form-inputs';
import { faCalendar, faClock } from '@fortawesome/free-solid-svg-icons';
import {
  IBookingFormData,
  BookingFormDataContext,
} from './booking-form.context';
import { TripJsonModel } from '../../../../admin/core/models/trip-model';
import { TripDetailsJsonModel } from '../../../../admin/core/models/trip-details-model';
import { weekDaysNames } from '../../../../core/utils/week-days';
import { timeFormatter } from '../../../../core/utils/time-formatter';
import { addDays } from '../../../../admin/pages/create-trips/create-trips';

interface IOneDirectionFormProps extends IDestinationVisualizationProps {}

export const getNextTripDate = (leaveDate: string): string => {
  let oldDay = new Date(leaveDate).getDay();
  let today = new Date().getDay();

  let differenceDaysCount = 0;
  for (let i = today; i !== oldDay; ) {
    differenceDaysCount++;
    i = ++i % 7;
  }
  return addDays(
    new Date().toISOString().substring(0, 10),
    differenceDaysCount + 1
  );
};

const OneDirectionForm = ({
  direction,
}: IOneDirectionFormProps): ReactElement => {
  const { dropdownsSelectedValues, setDropdownsSelectedValues } =
    useContext<IBookingFormData>(BookingFormDataContext);

  return (
    <>
      <LeavingPointFormInputs direction={direction} />
      <Row>
        <Col>
          <CustomDropdown<TripJsonModel>
            options={
              typeof dropdownsSelectedValues?.station !== 'undefined' 
                ? dropdownsSelectedValues.station.trips?.filter(
                  trips =>{
                   return  trips.tripsDetails
                   ?.some(
                      tripsDetails=>{
                        if (direction === 'goToUniversity')
                      {
                        return tripsDetails.destination === 'TO_UNIVERSITY';
                      }  
                          else if (direction === 'goToStation'){
                            return tripsDetails.destination === 'TO_STATION';

                          }
                            else return undefined
                    })
                  }
                ) 
                : undefined
            }
            value={dropdownsSelectedValues?.trip}
            setSelected={selectedDropdownValue => {
              setDropdownsSelectedValues({
                ...dropdownsSelectedValues,
                tripDetails: 
                 undefined,
                trip: selectedDropdownValue,
              });
            }}
            label="تاريخ التحرك"
            icon={faCalendar}
            selectedValueFromObject={trip => {
              return trip.leaveDate.includes('0001')
                ? `${
                    weekDaysNames[new Date(trip.leaveDate).getDay()]
                  } ${getNextTripDate(trip.leaveDate)}`
                : trip.leaveDate;
            }}
          />
        </Col>
        {(() => {
          return (
            <Col>
              <CustomDropdown<TripDetailsJsonModel>
                options={
                  typeof dropdownsSelectedValues?.trip !== 'undefined'
                    ? dropdownsSelectedValues.trip.tripsDetails?.filter(
                        // eslint-disable-next-line array-callback-return
                        tripDetails => {
                          if (direction === 'goToUniversity')
                            return tripDetails.destination === 'TO_UNIVERSITY';
                          else if (direction === 'goToStation')
                            return tripDetails.destination === 'TO_STATION';
                        }
                      )
                    : undefined
                }
                value={dropdownsSelectedValues?.tripDetails}
                setSelected={selectedDropdownValue => {
                  setDropdownsSelectedValues({
                    ...dropdownsSelectedValues,
                    tripDetails: selectedDropdownValue,
                  });
                }}
                label="وقت التحرك"
                icon={faClock}
                selectedValueFromObject={tripDetails =>
                  timeFormatter(tripDetails.leaveTime)
                }
              />
            </Col>
          );
        })()}
      </Row>
    </>
  );
};

export default OneDirectionForm;
