import { ReactElement, useEffect, useState } from 'react';
import { SchemaDropdown } from '../../../admin/core/interfaces/schema-dropdown';
import { JsonModelWithName } from '../../models/json-model-with-name';
import CustomDropdown, {
  ICustomDropdownProps,
} from '../custom-dropdown/custom-dropdown';
import withLoading from '../with-loading/with-loading';

const CustomDropdownWithLoading = withLoading(
  CustomDropdown<JsonModelWithName>
);

interface IDropdownAsyncDataProps
  extends ICustomDropdownProps<JsonModelWithName>,
    SchemaDropdown<JsonModelWithName> {}

const DropdownAsyncData = ({
  requestData,
  ...dropdownProps
}: IDropdownAsyncDataProps): ReactElement => {
  const [dropdownData, setDropdownData] = useState<JsonModelWithName[]>();

  const initialize = async () => {
    setDropdownData(await requestData());
  };

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CustomDropdownWithLoading
      isLoading={dropdownData === undefined}
      options={dropdownData}
      {...dropdownProps}
    />
  );
};

export default DropdownAsyncData;
