import axios, { AxiosStatic } from 'axios';
import { OrdinaryModel } from '../models/ordinary-model';
import { PaginatedResponse } from '../models/paginated-response';
import { Authentication } from '../utils/authentication';
import { ApiRequest } from './api-request';
import { DeleteRequestParams } from './request-params/delete-request-params';
import { GetAllRequestParams } from './request-params/get-all-request-params';
import { GetOneRequestParams } from './request-params/get-one-request-params';
import { GetRequestParams } from './request-params/get-request-params';
import { PaginatedRequestParams } from './request-params/paginated-request-params';
import { PatchRequestParams } from './request-params/patch-request-params';
import { PostRequestParams } from './request-params/post-request-params';

export class Api<ResModel> {
  private readonly apiHandler: AxiosStatic;
  private readonly apiUrl: string;

  constructor(
    apiUrl: string,
    apiHandler?: AxiosStatic,
    private readonly apiRequest: ApiRequest = new ApiRequest()
  ) {
    // axios.defaults.baseURL = 'http://localhost';
    axios.defaults.baseURL = 'https://newgoldenfly.com';
    const token = Authentication.getToken();
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

    this.apiHandler = apiHandler ?? axios;
    this.apiUrl = apiUrl;
  }

  async getAll(whereParams?: GetAllRequestParams): Promise<ResModel[]> {
    const { data } = await this.apiHandler.get<ResModel[]>(
      `${this.apiUrl}/all`,
      { params: whereParams }
    );
    return data;
  }

  async getAllWithDetails(): Promise<ResModel[]> {
    const { data } = await this.apiHandler.get<ResModel[]>(
      `${this.apiUrl}/all-with-details`
    );
    return data;
  }

  async getPaginated({
    filtersParameters,
    ...otherPagintaedParams
  }: PaginatedRequestParams) {
    const { data } = await this.apiHandler.get<PaginatedResponse<ResModel>>(
      `${this.apiUrl}?${filtersParameters}`,
      {
        params: { ...otherPagintaedParams, limit: 999999 },
      }
    );
    return data;
  }

  async get({ params }: GetRequestParams): Promise<ResModel> {
    return this.apiRequest.call<ResModel>(() =>
      this.apiHandler.get<ResModel>(`${this.apiUrl}`, { params })
    );
  }

  async getOne({ id }: GetOneRequestParams): Promise<ResModel> {
    return this.apiRequest.call<ResModel>(() =>
      this.apiHandler.get<ResModel>(`${this.apiUrl}/${id}`)
    );
  }

  async post<ReqModel>({
    data,
  }: PostRequestParams<ReqModel>): Promise<ResModel> {
    return this.apiRequest.call<ResModel>(() =>
      this.apiHandler.post<ResModel>(`${this.apiUrl}`, data)
    );
  }

  async update<ReqModel extends OrdinaryModel>({
    id,
    data,
  }: PatchRequestParams<ReqModel>): Promise<ResModel> {
    return this.apiRequest.call<ResModel>(() =>
      this.apiHandler.patch<ResModel>(`${this.apiUrl}/${id}`, data)
    );
  }

  async delete({ id }: DeleteRequestParams): Promise<void> {
    return this.apiRequest.call<void>(() =>
      this.apiHandler.delete(`${this.apiUrl}/${id}`)
    );
  }
}
