import { ReactElement } from 'react';
import './main-layout.scss';
import MainLayoutHeader, {
  IMainLayoutHeaderProps,
} from './components/main-layout-header/main-layout-header';
import Children from '../../../../core/types/children.type';
import { HeaderActions } from './components/main-layout-header-buttons/main-layout-header-buttons';
import { JsonModel } from '../../../../core/models/json-model';

interface IMainLayoutProps extends IMainLayoutHeaderProps {
  pageTitle: string;
  showHeader: boolean;
  children: Children;
  headerActions: HeaderActions;
  tableData?: JsonModel[];
}

const MainLayout = ({
  pageTitle,
  showHeader,
  children,
  ...props
}: IMainLayoutProps): ReactElement => {
  return (
    <div className="main-layout">
      <h1 className="main-layout__title display-3">{pageTitle}</h1>
      {showHeader && <MainLayoutHeader {...props} />}
      {children}
    </div>
  );
};

export default MainLayout;
