import { ReactElement, lazy, Suspense } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import Header from './core/components/header/header';
import Logo from './assets/images/logo.webp';
import { AdminRoutes, AppRoutes } from './core/constants/routes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import QrScanner from './user/pages/qr-scanner/qr-scanner';
import PrivateRoute from './core/components/private-router/private-route';
import { Authentication } from './core/utils/authentication';

const Home = lazy(() => import('./user/pages/home/home'));
const Auth = lazy(() => import('./user/pages/auth/auth'));
const Admin = lazy(() => import('./admin/admin'));

interface userAndAdmin {
  marginTop: string;
  height: string;
}

const App = (): ReactElement => {
  const { pathname } = useLocation();

  const user: userAndAdmin = {
    marginTop: '9.2rem',
    height: 'calc(90vh - 92px)',
  };

  const admin: userAndAdmin = {
    marginTop: '0',
    height: '100vh',
  };

  const adminPath = pathname.includes(AdminRoutes.admin);

  return (
    <div>
      {!adminPath && <Header logo={Logo} logoHeight={'6rem'} />}
      <div style={!adminPath ? user : admin}>
        <Suspense fallback={<h1>Loading...</h1>}>
          <Switch>
            <PrivateRoute
              exact
              isLoggedIn={Authentication.isAuthenticated()}
              path="/"
              component={() => <Home />}
            />
            <PrivateRoute
              exact
              isLoggedIn={Authentication.isAuthenticated()}
              path={AppRoutes.qrScanner}
              component={() => <QrScanner />}
            />
            <Route path={AppRoutes.auth} component={() => <Auth />} />
            <Route path={AdminRoutes.admin} component={() => <Admin />} />
          </Switch>
        </Suspense>
      </div>
      <ToastContainer />
    </div>
  );
};

export default App;
