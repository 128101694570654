export class Authentication {
  private static readonly storage = localStorage;
  private static readonly tokenKey: string = 'token';

  static isAuthenticated(): boolean {
    return !!Authentication.getToken();
  }

  static getToken(): string | null {
    return Authentication.storage.getItem(Authentication.tokenKey);
  }

  static setToken(token: string): string | null {
    Authentication.storage.setItem(Authentication.tokenKey, token);
    return Authentication.storage.getItem(Authentication.tokenKey);
  }
  static removeToken(): void {
    Authentication.storage.removeItem(Authentication.tokenKey);
  }
}
