import { ReactElement } from 'react';
import './customButton.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import IconAwesome from '../../types/icon-awesome.type';

type ButtonColor =
  | 'primary'
  | 'green'
  | 'red'
  | 'blue'
  | 'light-grey'
  | 'yellow';

export interface ICustomButton
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  icon?: IconAwesome;
  color: ButtonColor;
}

const CustomButton = ({
  text,
  icon,
  color,
  ...props
}: ICustomButton): ReactElement => {
  return (
    <button className={`custom-button ${color}`} {...props}>
      {text}

      {icon && <FontAwesomeIcon icon={icon} className="icon" />}
    </button>
  );
};

export default CustomButton;
