import { JsonModel } from '../../../core/models/json-model';
import IconAwesome from '../../../core/types/icon-awesome.type';
import { SchemaColumnTypeWithIcon } from './schema-column-type-with-icon';

export class SchemaDropdown<ResModel extends JsonModel>
  implements SchemaColumnTypeWithIcon
{
  requestData: () => Promise<ResModel[]>;
  icon?: IconAwesome;
  selectedValueFromObject: (selectedValue: ResModel) => string;

  constructor({
    icon: Icon,
    requestData,
    selectedValueFromObject,
  }: // responseToStringArray,
  {
    icon?: IconAwesome;
    requestData: () => Promise<ResModel[]>;

    selectedValueFromObject: (selectedValue: ResModel) => string;
  }) {
    this.icon = Icon;
    this.requestData = requestData;
    this.selectedValueFromObject = selectedValueFromObject;
  }
}
