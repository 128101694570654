import { useState } from 'react';

export type SetFormValueByInput = ({
  target: { value, name },
}: React.ChangeEvent<HTMLInputElement>) => void;

export type ChangeValueFn<T extends {}> = (values: T) => void;

const useFormInputs = <T extends {}>(
  initialValue?: T
): [T | undefined, SetFormValueByInput, ChangeValueFn<T>] => {
  const [formValues, setFormValues] = useState<T | undefined>(initialValue);

  const setValues = (values: T) =>
    typeof formValues === 'undefined'
      ? setFormValues(values)
      : setFormValues({ ...formValues, ...values });

  const setFormValueByInput = ({
    target: { value, name },
  }: React.ChangeEvent<HTMLInputElement>) => setValues({ [name]: value } as T);

  return [formValues, setFormValueByInput, setValues];
};

export default useFormInputs;
