import { createContext } from 'react';
import { RegionJsonModel } from '../../../../admin/core/models/region-model';
import { StationJsonModel } from '../../../../admin/core/models/station-model';
import { TripDetailsJsonModel } from '../../../../admin/core/models/trip-details-model';
import { TripJsonModel } from '../../../../admin/core/models/trip-model';

export interface IDropdownsSelectedValues {
  region?: RegionJsonModel;
  station?: StationJsonModel;
  trip?: TripJsonModel;
  tripDetails?: TripDetailsJsonModel;
}

export interface IBookingFormData {
  bookingFormData?: RegionJsonModel[];
  dropdownsSelectedValues?: IDropdownsSelectedValues;
  setDropdownsSelectedValues: (value: IDropdownsSelectedValues) => void;
}
const bookingFormData: IBookingFormData = {
  setDropdownsSelectedValues: _ => {},
};

export const BookingFormDataContext =
  createContext<IBookingFormData>(bookingFormData);
