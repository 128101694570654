import { randomString } from '../../utils/random';

export class DateFilter {
  private readonly _columnName: string;
  private readonly _condition: 'greaterThanOrEqual' | 'lowerThanOrEqual';
  private readonly _value: Date;
  private readonly _filterCode: string;

  constructor({
    columnName,
    condition,
    value,
  }: {
    columnName: string;
    condition: 'greaterThanOrEqual' | 'lowerThanOrEqual';
    value: Date;
  }) {
    this._filterCode = randomString();
    this._columnName = columnName;
    this._condition = condition;
    this._value = value;
  }

  get build(): string {
    return `filter.${this.columnName}=${this[this.condition]()}`;
  }

  private greaterThanOrEqual(): string {
    return `$gte:${this.value.toISOString()}`;
  }

  private lowerThanOrEqual(): string {
    return `$lte:${this.value.toISOString()}`;
  }

  public get columnName(): string {
    return this._columnName;
  }

  public get condition(): 'greaterThanOrEqual' | 'lowerThanOrEqual' {
    return this._condition;
  }

  public get value(): Date {
    return this._value;
  }

  public get filterCode(): string {
    return this._filterCode;
  }
}
