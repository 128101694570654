import { ReactElement } from 'react';
import { ReactComponent as Search } from '../../../../assets/icons/search.svg';
import { SetFormValueByInput } from '../../../../core/hook/use-form-inputs';
import './search-input.scss';

interface ISearchInput {
  onChange?: SetFormValueByInput;
  value?: string;
}

const SearchInput = ({ onChange, value }: ISearchInput): ReactElement => {
  return (
    <div className="search-input">
      <Search className="search-input__icon" />
      <input
        className="search-input__input"
        placeholder="بحث..."
        onChange={onChange}
        value={value}
        name="search"
        autoComplete="off"
      />
    </div>
  );
};

export default SearchInput;
