import { ReactElement, useState } from 'react';
import './custom-dropdown.scss';

import useComponentVisible from '../../hook/use-component-visible';
import IconAwesome from '../../types/icon-awesome.type';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

export interface ICustomDropdownProps<T> {
  options?: T[];
  setSelected: (newSelectedValue: T) => void;
  label?: string;
  icon?: IconAwesome;
  value?: T;
  selectedValueFromObject: (selectedValue: T) => string;
}

const CustomDropdown = <T extends {}>({
  options,
  setSelected,
  selectedValueFromObject,
  label,
  icon,
  value,
}: ICustomDropdownProps<T>): ReactElement => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  return (
    <div className="custom-dropdown" ref={ref}>
      <div className="custom-dropdown__label-and-icon">
        {label && icon && (
          <label className="custom-dropdown__label-and-icon__label">
            <FontAwesomeIcon
              icon={icon}
              className="custom-dropdown__label-and-icon__icon"
            />
            {label}
          </label>
        )}
      </div>
      <div
        className="custom-dropdown-btn"
        style={{ color: value ? '#3d3d3d' : '#989898' }}
        onClick={() => {
          setIsActive(!isActive);
        }}
      >
        <span>
          {typeof value === 'undefined'
            ? `اختر ${label ? label : ''}`
            : selectedValueFromObject(value)}
        </span>
        <FontAwesomeIcon
          icon={faChevronLeft}
          className="down-arrow"
          style={{ transform: 'rotate(270deg)' }}
        />
      </div>
      {isActive && isComponentVisible && options?.length ? (
        <div className="custom-dropdown-content">
          {options?.map((option, i) => (
            <div
              key={i}
              onClick={() => {
                setSelected(option);
                setIsComponentVisible(false);
                setIsActive(false);
              }}
              className="custom-dropdown-item"
            >
              {selectedValueFromObject(option)}
            </div>
          ))}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default CustomDropdown;
