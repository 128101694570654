import {
  faSave,
  faBan,
  faCalendar,
  faMapPin,
  faRoute,
  faCheck,
  faBus,
} from '@fortawesome/free-solid-svg-icons';
import { ReactElement } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Api } from '../../../core/api/api';
import CustomButton from '../../../core/components/customButton/customButton';
import CustomInput from '../../../core/components/customInput/customInput';
import DropdownAsyncData from '../../../core/components/dropdown-async-data/dropdown-async-data';
import showToast from '../../../core/components/Toast/Toast';
import useFormInputs from '../../../core/hook/use-form-inputs';
import ErrorMessages from '../../../core/messages/error-messages';
import SuccessMessages from '../../../core/messages/success-messages';
import { JsonModelWithName } from '../../../core/models/json-model-with-name';
import { OrdinaryModel } from '../../../core/models/ordinary-model';
import { weekDaysNames } from '../../../core/utils/week-days';
import { weekDaysWithIndex } from '../../../user/pages/auth/sign-up-form/sign-up-steps/sign-up-step-2/time-table-form';
import { stationCrud } from '../../core/cruds/station.crud';
import MainLayout from '../../core/layouts/main-layout/main-layout';
import { StationJsonModel } from '../../core/models/station-model';
import { TripFromJson, TripJsonModel } from '../../core/models/trip-model';

export function addDays(date: string, days: number): string {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result.toISOString().substring(0, 10);
}

function subtractHours(
  date: string,
  time: string,
  numOfHours: number
): string[] {
  const acsac = `${date}T${time}:00.000Z`;
  const result = new Date(acsac);

  result.setHours(result.getHours() - numOfHours);

  let d = result.toISOString().split('T');
  d[1] = d[1].split('.')[0].substring(0, 5);
  return d;
}

const CreateTrips = (): ReactElement => {
  const [formValues, setFormValueByInput, setValues] =
    useFormInputs<Partial<TripFromJson>>();
  const { goBack } = useHistory();

  const api = new Api<TripJsonModel>('/trip');

  const onSubmitCreate = async () => {
    if (
      formValues?.destination &&
      formValues?.leaveDate &&
      formValues?.leaveTime &&
      formValues?.station &&
      formValues?.isFixedTime
    ) {
      if (formValues?.isFixedTime?.value === true) {
        const [deadlineDate, deadlineTime] = subtractHours(
          addDays(formValues?.leaveDate, 7),
          formValues?.leaveTime,
          formValues?.deadlineTime
        );
        await api.post({
          data: {
            ...formValues,
            deadlineDate,
            deadlineTime,
          } as OrdinaryModel,
        });
      } else {
        if (formValues?.deadlineDate && formValues?.deadlineTime) {
          await api.post({
            data: {
              ...formValues,
            } as OrdinaryModel,
          });
        } else {
          showToast({ msg: ErrorMessages.FILL_IN_ALL_DATA, type: 'warning' });
        }
      }
      goBack();
      showToast({ msg: SuccessMessages.CREATED_SUCCESSFULLY, type: 'success' });
    } else {
      showToast({ msg: ErrorMessages.FILL_IN_ALL_DATA, type: 'warning' });
    }
  };

  return (
    <MainLayout
      headerActions={{
        showAddButton: false,
        showExcelButton: false,
        showFilterButton: false,
        showTripsNumber: false,
      }}
      showHeader={false}
      pageTitle={`إضافة الرحلة`}
    >
      <div className="create-or-edit-form">
        <DropdownAsyncData
          label="نوع الموعد"
          key="نوع الموعد"
          icon={faMapPin}
          value={formValues?.isFixedTime}
          setSelected={selectedDropdownValue =>
            setValues({
              deadlineDate: undefined,
              deadlineTime: undefined,
              destination: undefined,
              leaveDate: undefined,
              leaveTime: undefined,
              station: undefined,
              tripId: undefined,
              isFixedTime: selectedDropdownValue as any,
            })
          }
          requestData={async () => [
            {
              id: 1,
              name: 'ثابت',
              value: true,
            },
            {
              id: 2,
              name: 'مؤقت',
              value: false,
            },
          ]}
          selectedValueFromObject={selectedValue => selectedValue.name}
        />
        <DropdownAsyncData
          label={'المحطة'}
          icon={faBus}
          value={formValues?.station}
          setSelected={selectedDropdownValue =>
            setValues({
              station: selectedDropdownValue as any,
            })
          }
          requestData={async () => {
            const api = new Api<StationJsonModel>(stationCrud.apiUrl);
            return api.getAll();
          }}
          selectedValueFromObject={selectedValue => selectedValue.name}
        />
        {formValues?.isFixedTime?.value ? (
          <DropdownAsyncData
            label="يوم التحرك"
            icon={faCalendar}
            value={
              !formValues?.leaveDate
                ? undefined
                : ({
                    id: new Date(formValues?.leaveDate).getDay(),
                    name: weekDaysNames[
                      new Date(formValues?.leaveDate).getDay()
                    ],
                    value: new Date(
                      `0001-01-0${new Date(formValues?.leaveDate).getDay()}`
                    ),
                  } as JsonModelWithName)
            }
            setSelected={selectedDropdownValue => {
              setValues({
                leaveDate: new Date(
                  `0001-01-0${
                    selectedDropdownValue.id === 0
                      ? 7
                      : selectedDropdownValue.id
                  }`
                )
                  .toISOString()
                  .slice(0, 10),
              });
            }}
            requestData={async () =>
              (
                Object.keys(weekDaysWithIndex) as Array<
                  keyof typeof weekDaysWithIndex
                >
              ).map(weekDayIndex => ({
                id: +weekDayIndex,
                name: weekDaysWithIndex[weekDayIndex],
                value: new Date(`0001-01-0${weekDayIndex}`),
              }))
            }
            selectedValueFromObject={selectedValue => selectedValue.name}
          />
        ) : (
          <CustomInput
            value={
              typeof formValues !== 'undefined'
                ? formValues['leaveDate']
                : undefined
            }
            onChange={setFormValueByInput}
            label="تاريخ التحرك"
            name="leaveDate"
            type="date"
          />
        )}
        <CustomInput
          value={
            typeof formValues !== 'undefined'
              ? formValues['leaveTime']
              : undefined
          }
          onChange={setFormValueByInput}
          label="وقت التحرك"
          name="leaveTime"
          type="time"
        />
        {formValues?.isFixedTime?.value ? (
          <></>
        ) : (
          <CustomInput
            value={
              typeof formValues !== 'undefined'
                ? formValues['deadlineDate']
                : undefined
            }
            onChange={setFormValueByInput}
            label="اخر تاريخ للحجز"
            name="deadlineDate"
            type="date"
          />
        )}
        {formValues?.isFixedTime?.value ? (
          <CustomInput
            icon={faCalendar}
            label="عدد الساعات قبل اغلاق الحجز"
            name="deadlineTime"
            type="number"
            value={!formValues?.deadlineTime ? 0 : formValues.deadlineTime}
            onChange={setFormValueByInput}
            min={0}
            max={12}
          />
        ) : (
          <CustomInput
            value={
              typeof formValues !== 'undefined'
                ? formValues['deadlineTime']
                : undefined
            }
            onChange={setFormValueByInput}
            label="اخر وقت للحجز"
            name="deadlineTime"
            type="time"
          />
        )}
        <DropdownAsyncData
          label={'الوجهة'}
          icon={faRoute}
          value={formValues?.destination}
          setSelected={selectedDropdownValue =>
            setValues({
              destination: selectedDropdownValue as any,
            })
          }
          requestData={async () => [
            {
              id: 1,
              name: 'إلي الجامعة',
              value: 'TO_UNIVERSITY',
            },
            {
              id: 2,
              name: 'إلي المحطة',
              value: 'TO_STATION',
            },
          ]}
          selectedValueFromObject={selectedValue => selectedValue.name}
        />
        <DropdownAsyncData
          label={'الحالة'}
          icon={faCheck}
          value={formValues?.isActive}
          setSelected={selectedDropdownValue =>
            setValues({
              isActive: selectedDropdownValue as any,
            })
          }
          requestData={async () => [
            {
              id: 1,
              name: 'مفعل',
              value: true,
            },
            {
              id: 2,
              name: 'معطل',
              value: false,
            },
          ]}
          selectedValueFromObject={selectedValue => selectedValue.name}
        />
        <Row>
          <Col>
            <CustomButton
              color="green"
              text="إضافة"
              icon={faSave}
              onClick={onSubmitCreate}
            />
          </Col>
          <Col>
            <CustomButton
              color="light-grey"
              text="إلغاء"
              icon={faBan}
              onClick={goBack}
            />
          </Col>
        </Row>
      </div>
    </MainLayout>
  );
};

export default CreateTrips;
