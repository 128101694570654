import { ReactElement, useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import CustomDropdown from '../../../../core/components/custom-dropdown/custom-dropdown';
import DestinationVisualization, {
  IDestinationVisualizationProps,
} from './destination-visualization/destination-visualization';
import { faBus, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { StationJsonModel } from '../../../../admin/core/models/station-model';
import { RegionJsonModel } from '../../../../admin/core/models/region-model';
import {
  BookingFormDataContext,
  IBookingFormData,
} from './booking-form.context';

interface ILeavingPointFormInputsProps extends IDestinationVisualizationProps {}

const LeavingPointFormInputs = (
  leavingPointFormInputsProps: ILeavingPointFormInputsProps
): ReactElement => {
  const {
    bookingFormData,
    dropdownsSelectedValues,
    setDropdownsSelectedValues,
  } = useContext<IBookingFormData>(BookingFormDataContext);

  return (
    <>
      <CustomDropdown<RegionJsonModel>
        options={bookingFormData}
        value={dropdownsSelectedValues?.region}
        selectedValueFromObject={selectedValue => selectedValue.name}
        setSelected={selectedDropdownValue => {
          setDropdownsSelectedValues({
            ...dropdownsSelectedValues,
            region: selectedDropdownValue,
            station: undefined,
            trip: undefined,
            tripDetails: undefined,
          });
        }}
        label="المنطقة"
        icon={faLocationDot}
      />
      <Row>
        <Col>
          <CustomDropdown<StationJsonModel>
            options={
              typeof dropdownsSelectedValues?.region !== 'undefined'
                ? dropdownsSelectedValues.region.stations
                : undefined
            }
            value={dropdownsSelectedValues?.station}
            setSelected={selectedDropdownValue => {
              setDropdownsSelectedValues({
                ...dropdownsSelectedValues,
                station: selectedDropdownValue,
                trip: undefined,
                tripDetails: undefined,
              });
            }}
            label="المحطة"
            icon={faBus}
            selectedValueFromObject={station => station.name}
          />
        </Col>
        <DestinationVisualization {...leavingPointFormInputsProps} />
      </Row>
    </>
  );
};

export default LeavingPointFormInputs;
