import { FC } from 'react';

type LoaderProps = {
  isLoading: boolean;
};
type WithLoaderProps<T> = T & LoaderProps;

const withLoading =
  <P extends {}>(
    WrappedComponent: FC<WithLoaderProps<P>>
  ): FC<WithLoaderProps<P>> =>
  ({ isLoading, ...props }: any) => {
    return <WrappedComponent {...props} />;
  };

export default withLoading;
