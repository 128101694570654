import { StationJsonModel } from '../models/station-model';

import {
  faBus,
  faCheck,
  faLocationDot,
} from '@fortawesome/free-solid-svg-icons';
import { ICrudRequired } from '../interfaces/crud.inteface';
import { SchemaDropdown } from '../interfaces/schema-dropdown';
import { SchemaInput } from '../interfaces/schema-input';
import { AdminRoutes } from '../../../core/constants/routes';
import { Api } from '../../../core/api/api';
import { RegionJsonModel } from '../models/region-model';
import { regionCrud } from './region.crud';

export const stationCrud: ICrudRequired<StationJsonModel> = {
  pageTitle: 'المحطات',
  itemName: 'محطة',
  path: AdminRoutes.stations,
  apiUrl: '/station',
  fromJson: station => {
    const {
      id,
      name,
      region: { name: regionName },
      isActive,
    } = station;

    return {
      id,
      name,
      region: regionName,
      isActive: isActive ? 'مفعل' : 'معطل',
    };
  },
  headerActions: {
    showAddButton: true,
    showFilterButton: false,
    showTripsNumber: false,
    showExcelButton: true,
  },
  actions: {
    showViewButton: false,
    showDeleteButton: false,
    showEditButton: true,
  },
  schema: {
    id: {
      uiColumnName: 'الرقم',
      type: new SchemaInput(),
      editable: false,
    },
    name: {
      uiColumnName: 'المحطة',
      type: new SchemaInput(faBus),
      editable: true,
    },
    region: {
      uiColumnName: 'المنطقة',
      type: new SchemaDropdown<RegionJsonModel>({
        icon: faLocationDot,
        requestData: async () => {
          const api = new Api<RegionJsonModel>(regionCrud.apiUrl);
          return api.getAll();
        },
        selectedValueFromObject: selectedRegion => selectedRegion.name,
      }),

      editable: true,
    },

    isActive: {
      uiColumnName: 'الحالة',
      type: new SchemaDropdown({
        icon: faCheck,
        requestData: async () => [
          {
            id: 1,
            name: 'مفعل',
            value: true,
          },
          {
            id: 2,
            name: 'معطل',
            value: false,
          },
        ],

        selectedValueFromObject: ({ name }) => name,
      }),
      editable: true,
    },
  },
};
