import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactElement } from 'react';
import IconAwesome from '../../types/icon-awesome.type';
import './customInput.scss';

export interface ICustomInputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  label?: string;
  icon?: IconAwesome;
}

const CustomInput = ({
  label,
  icon,
  ...props
}: ICustomInputProps): ReactElement => {
  return (
    <div className="custom-input-container">
      <div className="custom-input-container__label-and-icon">
        {label && (
          <label className="custom-input-container__label-and-icon__label">
            {icon && (
              <FontAwesomeIcon
                icon={icon}
                className="custom-input-container__label-and-icon__icon"
              />
            )}
            {label}
          </label>
        )}
      </div>
      <input className="custom-input-container__custom-input" {...props} />
    </div>
  );
};

export default CustomInput;
