import {
  faCircleCheck,
  faSignIn,
  faSignOut,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactElement } from 'react';
import { Navbar, Container, Nav } from 'react-bootstrap';
import { NavLink, useHistory } from 'react-router-dom';
import { AppRoutes } from '../../constants/routes';
import { Authentication } from '../../utils/authentication';
import { useRemToPx } from '../../utils/unit-converter';
import './header.scss';

interface IHeader {
  logo: string;
  logoWidth?: number | string;
  logoHeight?: number | string;
}

const Header = ({ logo, logoWidth, logoHeight }: IHeader): ReactElement => {
  const { push: navigate } = useHistory();
  return (
    <Navbar fixed="top" expand="lg">
      <Container fluid className="header">
        <NavLink to="/">
          <Navbar.Brand>
            <img
              src={logo}
              alt="logo"
              // eslint-disable-next-line react-hooks/rules-of-hooks
              width={logoWidth ? useRemToPx(logoWidth) : undefined}
              // eslint-disable-next-line react-hooks/rules-of-hooks
              height={logoHeight ? useRemToPx(logoHeight) : undefined}
            />
          </Navbar.Brand>
        </NavLink>

        <Nav className="me-auto">
          {Authentication.isAuthenticated() && (
            <NavLink className="inactive" to={AppRoutes.qrScanner}>
              <Nav>تأكيد الحجز</Nav>
              <FontAwesomeIcon
                icon={faCircleCheck}
                size="2x"
                className="icon"
              />
            </NavLink>
          )}
          {!Authentication.isAuthenticated() ? (
            <NavLink className="inactive" to={AppRoutes.signIn}>
              <Nav>تسجيل الدخول</Nav>
              <FontAwesomeIcon icon={faSignIn} size="2x" className="icon" />
            </NavLink>
          ) : (
            <div
              className="inactive"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                Authentication.removeToken();
                navigate('/');
              }}
            >
              <Nav>تسجيل الخروج</Nav>
              <FontAwesomeIcon icon={faSignOut} size="2x" className="icon" />
            </div>
          )}
        </Nav>
      </Container>
    </Navbar>
  );
};

export default Header;
