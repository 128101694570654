import { RegionJsonModel } from '../models/region-model';
import { faCheck, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { ICrudRequired } from '../interfaces/crud.inteface';
import { SchemaInput } from '../interfaces/schema-input';
import { SchemaQr } from '../interfaces/schema-qr';
import { AdminRoutes } from '../../../core/constants/routes';
import { SchemaDropdown } from '../interfaces/schema-dropdown';

export const regionCrud: ICrudRequired<RegionJsonModel> = {
  pageTitle: 'المناطق',
  itemName: 'منطقة',
  path: AdminRoutes.regions,
  apiUrl: '/region',
  fromJson: ({ name, id, isActive }) => ({
    id,
    name,
    isActive: isActive ? 'مفعل' : 'معطل',
  }),
  headerActions: {
    showAddButton: true,
    showFilterButton: false,
    showTripsNumber: false,
    showExcelButton: true,
  },
  actions: {
    showViewButton: true,
    showDeleteButton: false,
    showEditButton: true,
  },
  schema: {
    id: {
      uiColumnName: 'الرقم',
      type: new SchemaInput(),
      editable: false,
    },
    name: {
      uiColumnName: 'المنطقة',
      type: new SchemaInput(faLocationDot),
      editable: true,
    },

    qr: {
      uiColumnName: 'QR',
      type: new SchemaQr(),
      editable: false,
      hiddenFromViewTable: true,
    },
    isActive: {
      uiColumnName: 'الحالة',
      type: new SchemaDropdown({
        icon: faCheck,
        requestData: async () => [
          {
            id: 1,
            name: 'مفعل',
            value: true,
          },
          {
            id: 2,
            name: 'معطل',
            value: false,
          },
        ],
        selectedValueFromObject: ({ name }) => name,
      }),
      editable: true,
    },
  },
};
