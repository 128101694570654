import { Redirect, Route } from 'react-router-dom';
import { AppRoutes } from '../../constants/routes';

const PrivateRoute = ({
  component: Component,
  isLoggedIn,
  ...rest
}: {
  component: any;
  isLoggedIn: boolean;
  [prop: string]: any;
}) => {
  return (
    <Route
      {...rest}
      render={props =>
        isLoggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: AppRoutes.signIn, state: { from: props.location } }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
