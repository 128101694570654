import { AxiosError, AxiosResponse } from 'axios';
import showToast from '../components/Toast/Toast';
import ErrorMessages from '../messages/error-messages';

export class ApiRequest {
  async call<ResModel>(
    callback: () => Promise<AxiosResponse<ResModel>>
  ): Promise<ResModel> {
    try {
      const { data } = await callback();
      return data;
    } catch (e) {
      if (e instanceof AxiosError) {
        // if (e.response?.status === 401) {
        //   showToast({ msg: ErrorMessages.UNAUTHORIZED, type: 'error' });
        // } else
        if (e.response?.status === 409) {
          showToast({ msg: ErrorMessages.DUPLICATED, type: 'error' });
        } else if (e.response?.status === 404) {
          showToast({ msg: ErrorMessages.WRONGE_CREDENTIALS, type: 'error' });
        }
      } else
        showToast({ msg: ErrorMessages.PLEASE_TRY_AGAIN_LATER, type: 'error' });
    }
    throw new Error('Unhandled ApiRequest error');
  }
}
