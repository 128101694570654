import { ReactElement } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import CustomButton, {
  ICustomButton,
} from '../../../../core/components/customButton/customButton';
import './custom-modal.scss';
import { faBan } from '@fortawesome/free-solid-svg-icons';
import Children from '../../../../core/types/children.type';

interface ICusomModalProps extends ICustomButton {
  toggleModal: () => void;
  showModal: boolean;
  children: Children;
}

const CustomModal = ({
  toggleModal,
  showModal,
  children,
  ...props
}: ICusomModalProps): ReactElement => {
  return (
    <Modal
      centered
      show={showModal}
      onHide={toggleModal}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      className="custom-modal"
    >
      <Modal.Body className="custom-modal__body">{children}</Modal.Body>
      <Row className="footer d-flex justify-content-start px-5 py-4 ">
        <Col md={6}>
          <CustomButton
            color="light-grey"
            text="إلغاء"
            icon={faBan}
            onClick={toggleModal}
          />
        </Col>
        <Col md={6} className="pt-3 pt-md-0">
          <CustomButton {...props} />
        </Col>
      </Row>
    </Modal>
  );
};

export default CustomModal;
