import { useState } from 'react';
import { faTable, faFilter, faPlus } from '@fortawesome/free-solid-svg-icons';
import { ReactElement } from 'react';
import { Col, Row } from 'react-bootstrap';
import { DateFilter } from '../../../../../../core/api/filters/date-filter';
import CustomButton from '../../../../../../core/components/customButton/customButton';
import { useHistory } from 'react-router-dom';
import CustomModal from '../../../../components/modal/custom-modal';
import CustomInput from '../../../../../../core/components/customInput/customInput';
import './main-layout-header-buttons.scss';
import { JsonModel } from '../../../../../../core/models/json-model';

export interface HeaderActions {
  showTripsNumber?: boolean;
  showAddButton?: boolean;
  showFilterButton?: boolean;
  showExcelButton?: boolean;
}

export interface IMainLayoutHeaderButtonsProps extends HeaderActions {
  addButtonText?: string;
  headerActions: HeaderActions;
  onClickDownloadExcel?: () => void;
  appendFilter?: (dateFilter: DateFilter) => void;
  path?: string;
  tableData?: JsonModel[];
}

const MainLayoutHeaderButtons = ({
  addButtonText,
  headerActions,
  onClickDownloadExcel,
  appendFilter,
  path,
  tableData,
}: IMainLayoutHeaderButtonsProps): ReactElement => {
  const { push: navigate } = useHistory();
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => setShowModal(!showModal);

  const onSubmitFilterTable = () => {
    if (typeof appendFilter !== 'undefined')
      appendFilter(
        new DateFilter({
          columnName: 'dateColumn',
          condition: 'greaterThanOrEqual',
          value: new Date(),
        })
      );
  };

  return (
    <>
      <Row className="gx-3">
        {headerActions.showTripsNumber && (
          <Col>
            <span className="trips-number">
              العدد: {tableData?.length ?? 0}
            </span>
          </Col>
        )}
        {headerActions.showExcelButton && (
          <Col>
            <CustomButton
              color="green"
              text="تحميل Excel"
              icon={faTable}
              onClick={onClickDownloadExcel}
            />
          </Col>
        )}
        {headerActions.showFilterButton && (
          <Col>
            <CustomButton
              color="blue"
              text="تصفية النتائج"
              icon={faFilter}
              onClick={toggleModal}
            />
          </Col>
        )}
        {headerActions.showAddButton && (
          <Col>
            <CustomButton
              color="yellow"
              text={addButtonText ? addButtonText : ''}
              icon={faPlus}
              onClick={() => {
                navigate(`${path}/create`);
              }}
            />
          </Col>
        )}
      </Row>
      {headerActions.showFilterButton && (
        <CustomModal
          color="blue"
          text="تصفية"
          toggleModal={toggleModal}
          showModal={showModal}
          icon={faFilter}
          onClick={onSubmitFilterTable}
        >
          <Row className="filter-modal-inputs-body">
            <h2>تصفية بالتاريخ</h2>
            <Col md={6}>
              <CustomInput type="date" />
            </Col>
            <Col md={6}>
              <CustomInput type="date" />
            </Col>
          </Row>
          {/* <Row className="filter-modal-inputs-body">
            <h2>تصفية بالأيام</h2>
            <Col md={6}>
              <CustomDropdown item={weekDays} label="اليوم من" />
            </Col>
            <Col md={6}>
              <CustomDropdown item={weekDays} label="اليوم إلي" />
            </Col>
          </Row> */}
        </CustomModal>
      )}
    </>
  );
};

export default MainLayoutHeaderButtons;
