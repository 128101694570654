export class AppRoutes {
  static auth: string = '/auth';

  static signIn: string = `${this.auth}/sign-in`;
  static signUp: string = `${this.auth}/sign-up`;
  static timeTable: string = `time-table`;
  static qrScanner: string = `/scan-trip`;
}

export class AdminRoutes {
  static admin: string = '/admin';

  static regions: string = `${this.admin}/regions`;
  static stations: string = `${this.admin}/stations`;
  static trips: string = `${this.admin}/trips`;
  static passengers: string = `${this.admin}/passengers`;
  static bookings: string = `${this.admin}/bookings`;
  static adminUsers: string = `${this.admin}/admin-users`;
}
