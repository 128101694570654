export default class SuccessMessages {
  static UPDATED_SUCCESSFULLY = 'تم التعديل بنجاح';
  static CREATED_SUCCESSFULLY = 'تمت الإضافة بنجاح';
  static DELETED_SUCCESSFULLY = 'تم الحذف بنجاح';
  static QR_DOWNLOADED_SUCCESSFULLY = 'تم تحميل الQR بنجاح';
  static EXCEL_DOWNLOADED_SUCCESSFULLY = 'تم تحميل الExcel بنجاح';
  static REGISER_SUCCESSFULLY = 'تم إنشاء حسابك بنجاح';
  static BOOKED_SUCCESSFULLY = 'تم الحجز بنجاح';
  static SIGNIN_SUCCESSFULLY = 'تم تسجيل الدخول بنجاح';
}
